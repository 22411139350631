{
    class AflVaultTypeFilterInputController {
        constructor() {
            this.debounce = 2000;
        }

        onUpdate() {
            this.onUpdateType({
                type: this.type
            });
        }
    }

    angular.module('adminApp')
        .component('aflVaultTypeFilterInput', {
            controller: AflVaultTypeFilterInputController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-type-filter-input/afl-vault-type-filter-input.html',
            bindings: {
                type: '=',
                onUpdateType: '&'
            }
        })
}
